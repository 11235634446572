'use client';
import { FC, useEffect } from 'react';
import Script from 'next/script';
import { usePathname } from 'next/navigation';

interface GaugesProps {
  siteId: string;
}

const Gauges: FC<GaugesProps> = ({ siteId }) => {
  const path = usePathname();

  // Log to Gauges when path changes
  useEffect(() => {
    // @ts-expect-error Call Gauges tracking function
    if (window._gauges !== undefined) {
      // @ts-expect-error Call Gauges tracking function
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- Calling Gauges tracking function
      window._gauges.push(['track']);
    }
  }, [path]);

  return (
    <>
      <Script id="gauges-tracker-script">
        {`window._gauges = window._gauges || [];
      (function() {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = true;
        t.id = 'gauges-tracker';
        t.setAttribute('data-site-id', '${siteId}');
        t.src = 'https://secure.gaug.es/track.js';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(t, s);
      })();`}
      </Script>
    </>
  );
};

export default Gauges;
