'use client';
import {
  CSSProperties,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { usePathname } from 'next/navigation';
import { getIndexForPath, sections } from '@/lib/routePositions';
import useOffset from '@/hooks/useOffset';

import classes from './StarfieldBackground.module.css';
import cx from 'clsx';

const stepCount = sections.length;

interface StarfieldBackgroundProps extends PropsWithChildren {
  className?: string;
}

const StarfieldBackground: FC<StarfieldBackgroundProps> = ({
  className,
  children,
}) => {
  const [position, setPosition] = useState(0);
  const path = usePathname();
  const offset = useOffset(position, stepCount);

  useEffect(() => {
    const position = getIndexForPath(path);
    if (position !== undefined) {
      setPosition(position);
    }
  }, [path]);

  return (
    <div
      className={cx(classes.root, className)}
      style={
        {
          '--backgroundOffset': offset,
        } as CSSProperties
      }>
      <div className={classes.light} />
      <div className={classes.stars}>{children}</div>
    </div>
  );
};

export default StarfieldBackground;
