import { useEffect, useState } from 'react';

const useOffset = (position: number, stepCount: number) => {
  const [lastPosition, setLastPosition] = useState(position);
  const [offset, setOffset] = useState(position);
  const maxPosition = stepCount - 1;

  useEffect(() => {
    if (position !== lastPosition) {
      if (lastPosition === maxPosition && position === 0) {
        setOffset(offset + 1);
        setLastPosition(0);
        return;
      }

      if (lastPosition === 0 && position === maxPosition) {
        setOffset(offset - 1);
        setLastPosition(maxPosition);
        return;
      }

      const difference = position - lastPosition;
      setOffset(offset + difference);
      setLastPosition(position);
    }
  }, [position, lastPosition, offset, maxPosition]);

  return offset;
};

export default useOffset;
