import { FC } from 'react';
import classes from './Globe.module.css';
import cx from 'clsx';
import useOffset from '@/hooks/useOffset';

interface GlobeProps {
  position: number;
  stepCount?: number;
  className?: string;
}

const Globe: FC<GlobeProps> = ({ position = 0, stepCount = 5, className }) => {
  const offset = useOffset(position, stepCount);

  return (
    <div
      className={cx(classes.root, className)}
      style={
        {
          '--Globe-stepCount': stepCount,
          '--Globe-position': offset,
        } as React.CSSProperties
      }>
      <div className={cx(classes.lensFlare, classes.layer)}></div>
      <div className={cx(classes.aurora, classes.layer)}></div>
      <div
        className={cx(classes.crust, classes.planetSlice, classes.layer)}></div>
      <div
        className={cx(
          classes.terrain,
          classes.planetSlice,
          classes.layer
        )}></div>
      <div
        className={cx(classes.scrub, classes.planetSlice, classes.layer)}></div>
    </div>
  );
};

export default Globe;
