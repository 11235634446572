'use client';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { getIndexForPath, sections } from '@/lib/routePositions';

import Globe from '@/components/Globe/Globe';
import classes from './OrbitalNavigation.module.css';
import cx from 'clsx';
import Link from 'next/link';

// interface OrbitalNavigationProps extends PropsWithChildren {}

const OrbitalNavigation: FC = ({}) => {
  const [position, setPosition] = useState(0);
  const path = usePathname();

  const nextLinkIndex = position + 1 >= sections.length ? 0 : position + 1;
  const previousLinkIndex =
    position - 1 < 0 ? sections.length - 1 : position - 1;

  const nextLink = sections[nextLinkIndex];
  const previousLink = sections[previousLinkIndex];

  useEffect(() => {
    const position = getIndexForPath(path);
    if (position !== undefined) {
      setPosition(position);
    }
  }, [path]);

  return (
    <div
      className={classes.root}
      style={
        {
          '--backgroundOffset': position,
        } as CSSProperties
      }>
      <nav className={classes.pagination}>
        <ul className={classes.menu}>
          <li className={classes.menuItem}>
            <Link
              className={cx(classes.arrowLink, classes.nextArrow)}
              href={nextLink.path}
              title={`Next page: ${nextLink.label}`}>
              {`Next page: ${nextLink.label}`}
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link
              className={cx(classes.arrowLink, classes.previousArrow)}
              href={previousLink.path}
              title={`Previous page: ${previousLink.label}`}>
              {`Previous page: ${previousLink.label}`}
            </Link>
          </li>
        </ul>
      </nav>

      <Globe
        className={cx(classes.planet)}
        position={position}
        stepCount={sections.length}
      />
    </div>
  );
};

export default OrbitalNavigation;
